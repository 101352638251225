/*
* Subnav
*/

// constants
const menuItems = document.querySelectorAll('.nav-main__item [aria-haspopup=true]');


// foreach menuitem with submenu
menuItems.forEach((item) => {
  item.addEventListener('click', function(e){
    e.preventDefault();

    let subnav = document.getElementById('subnav-' + e.target.id);

    subnav.classList.toggle('visually-hidden');
    subnav.toggleAttribute('aria-hidden');

    // link
    e.target.parentNode.classList.toggle('is-open');
    e.target.getElementsByTagName("a")[0].toggleAttribute('aria-expanded');
  })
});

export default menuItems;
