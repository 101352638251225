/*
* Mobile Menu
*/

// constants
const mobileMenuButton = document.getElementById('menubutton');
const mobileMenuList = document.getElementById('menu'); 

mobileMenuButton.addEventListener('click', function(e){
  // menu
  mobileMenuList.classList.toggle('open');

  // button
  e.target.toggleAttribute('aria-expanded');
  e.target.classList.toggle('open');
});


export default mobileMenuButton;
