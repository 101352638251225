var employeeSwiper = new Swiper('.swiper-container', {
    lazy: true,
    speed: 800,
    loop: true,
    parallax: true,
    autoplay: {
      delay: 5000,
    },

    // navigation: {
    //    nextEl: '.swiper-button-next',
    //    prevEl: '.swiper-button-prev',
    //  },
});

export default employeeSwiper;
