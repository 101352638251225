'use strict';

/*
 * Importing functions ..
 */

import featureDetection from './partials/featureDetection';
import employeeSwiper from './partials/employee-swiper';
import subNav from './partials/subnav';
import mobileMenu from './partials/mobileMenu';
